import React from 'react';
import moment from 'moment-timezone';

import Grid from '@material-ui/core/Grid';
import SessionBottomInfoBar from '../../components/SessionBottomInfoBar/SessionBottomInfoBar';
import WaitingRoomContent from '../../components/SessionBottomInfoBar/WaitingRoomBottomContent';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    background: '#08152E',
    flexDirection: 'column',
    overflowX: 'hidden',
    cursor: 'default',
  },
  bodyContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: '700px',
    padding: '2em',
    height: 'calc(100vh - 100px)',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    padding: '.5em 1em',
    color: '#f2f2f2',
  },
  leaderboardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    minWidth: '450px',
    height: 'calc(100% - 150px)',
    minHeight: '380px',
    padding: '2em',
    margin: '4em 0',
    background: 'none',
    color: 'black',
  },
  header: {
    fontSize: '32px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#f2f2f2',
  },
  sessions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    margin: '1em',
    fontSize: '24px',
    width: '100%',
  },
  leaderboardColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#f2f2f2',
  },
  leaderboardHeader: {
    fontSize: '24px',
  },
  playerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minWidth: '600px',
    height: 'calc(100% - 150px)',
    minHeight: '380px',
    padding: '2em',
    margin: '2em',
    background: 'black',
    color: 'white',
    borderRadius: '10px',
    boxShadow: '0 0 25px 0 rgba(74, 255, 255, 0.45)',
  },
});

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

export default function WaitingRoomPage(props: any) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.title}>Waiting Area</div>
      <Grid alignItems='flex-start' className={classes.container}>
        <div className={classes.bodyContainer}>
          <div className={classes.leaderboardContainer}>
            <div className={classes.header}>Teams to Beat</div>
            <div className={classes.sessions}>
              <div className={classes.leaderboardColumn}>
                <div style={{ fontWeight: 'bold' }}>Rank</div>
                <div style={{ margin: '10px 0', textAlign: 'center' }}>
                  <div>1</div>
                  <div>2</div>
                  <div>3</div>
                  <div>4</div>
                  <div>5</div>
                </div>
              </div>
              <div className={classes.leaderboardColumn}>
                <div style={{ fontWeight: 'bold' }}>Team</div>
                <div style={{ margin: '10px 0', textAlign: 'center' }}>
                  <div>Tropical Security</div>
                  <div>CyberSmartz</div>
                  <div>Haxxors</div>
                  <div>Team 733T</div>
                  <div>Catch Us If You Can</div>
                </div>
              </div>
              <div className={classes.leaderboardColumn}>
                <div style={{ fontWeight: 'bold' }}>Time</div>
                <div style={{ margin: '10px 0', textAlign: 'center' }}>
                  <div>00:46:17</div>
                  <div>00:51:48</div>
                  <div>00:51:53</div>
                  <div>00:56:07</div>
                  <div>00:56:45</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.playerContainer}>Coming Soon!</div>
        </div>
      </Grid>
      <SessionBottomInfoBar>
        <WaitingRoomContent
          sessionDetails={{
            id: 'nawiXlDhN90XYL0kgNVp',
            start_time: moment().add(1, 'h').startOf('h'),
            end_time: moment().add(2, 'h').startOf('h'),
            reschedule_link: '',
          }}
        />
      </SessionBottomInfoBar>
    </ThemeProvider>
  );
}
