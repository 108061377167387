import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useHeight from '../../../../hooks/useHeight/useHeight';
import useWidth from '../../../../hooks/useWidth/useWidth';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles({
  puzzleContainer: {
    width: '100%',
  },
  frame: {
    border: 0,
    outline: 0,
  },
  toggleBtn: {
    padding: '8px 20px',
    border: '0',
    width: '200px',
    margin: '-20px auto 20px',
    textAlign: 'center',
    cursor: 'pointer',
    background: '#6ebddb',
    borderRadius: '10px',
    color: 'white',
  },
});

const LOCKER = 'Evidence Locker';
const PUZZLE = 'Puzzle';

export default function PuzzleView(props: any) {
  const classes = useStyles();
  const [currentView, toggleView] = useState(PUZZLE);
  const height = useHeight(-250);
  const width = useWidth(-580);
  const { localTracks } = useVideoContext();

  localTracks.forEach(track => {
    if (track.kind === 'audio') {
      track.enable();
    }
  });

  return (
    <div>
      <div>
        <div onClick={toggleView.bind(null, currentView === PUZZLE ? LOCKER : PUZZLE)} className={classes.toggleBtn}>
          {`Toggle ${currentView === PUZZLE ? LOCKER : PUZZLE}`}
        </div>
      </div>
      <div className={classes.puzzleContainer}>
        {currentView === PUZZLE ? (
          <iframe
            className='frame'
            src='https://cmclassify.livingsecurity.com'
            width={width}
            height={height}
            frameBorder='0'
            title='Classify Puzzle'
          />
        ) : (
          <iframe
            className='frame'
            src='https://evidence.livingsecurity.com'
            width={width}
            height={height}
            frameBorder='0'
            title='Evidence Locker'
          />
        )}
      </div>
    </div>
  );
}
