import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import CloudinaryPlayer from './CloudinaryPlayer.js';

const useStyles = makeStyles({
  videoContainer: {
    width: '720px',
    height: '400px',
    boxShadow: '0 0 25px 0 rgba(74, 255, 255, 0.45)',
    marginBottom: '30px',
  },
});

export default function VideoView({ videoId, width }: { videoId?: string; width?: string }) {
  const classes = useStyles();
  const { localTracks } = useVideoContext();

  // This disables each player's microphone
  localTracks.forEach(track => {
    if (track.kind === 'audio') {
      track.disable();
    }
  });

  return (
    <div>
      <div className={classes.videoContainer}>
        <CloudinaryPlayer publicId={videoId} width={width} />
      </div>
    </div>
  );
}
