import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';
import Controls from '../Controls/Controls';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#08152E',
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();

  return (
    <AppBar className={classes.container} position='static'>
      <Toolbar className={classes.toolbar}>
        <div className={classes.rightButtonContainer}>
          <Controls embedded />
          <Menu />
        </div>
      </Toolbar>
    </AppBar>
  );
}
