import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  modalContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    background: '#012432',
    margin: '100px auto',
    width: 'auto',
    height: 'auto',
    color: '#F2F2F2',
    boxShadow: '0 0 25px 0 rgba(74, 255, 255, 0.45)',
  },
  header: {
    height: '60px',
    width: '100%',
    background: '#2d9cdb',
    padding: '.5em 1em',
    fontSize: '26px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  body: {
    position: 'relative',
    padding: '1em 2em',
  },
});

export default function (props: any) {
  const classes = useStyles();
  return (
    <div className={classes.modalContainer}>
      <div className={classes.header}>{props.title}</div>
      <div className={classes.body}>{props.children}</div>
    </div>
  );
}
