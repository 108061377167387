import React from 'react';
import Grid from '@material-ui/core/Grid';
import logo from '../../assets/images/logo.png';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    background: '#08152E',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '600px',
    padding: '1em',
    cursor: 'pointer',
  },
});

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

export default function LandingPage(props: any) {
  const classes = useStyles();
  const history = useHistory();

  const selectSession = () => {
    history.push({ pathname: `/nawiXlDhN90XYL0kgNVp` });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container alignItems='flex-start' className={classes.container}>
        <img className={classes.logo} src={logo} alt='Logo' onClick={selectSession} />
      </Grid>
    </ThemeProvider>
  );
}
