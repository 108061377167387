import React, { useState } from 'react';
import * as firebase from 'firebase/app';
import { useDocument } from 'react-firebase-hooks/firestore';
import { GAME_DEF_COLLECTION } from '../../db';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

import QuizView from './views/QuizView/QuizView';
import VideoView from './views/VideoView/VideoView';
import PuzzleView from './views/PuzzleView/PuzzleView';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    background: '#08152E',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minWidth: '400px',
    padding: '2em',
    background: 'white',
    color: 'black',
  },
  button: {
    margin: '1em',
    background: '#6ebddb',
    color: '#FFF',
    width: '60px',
    '&:hover': {
      background: '#5B9CB5',
    },
  },
});

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

export default function GameplayPage(props: any) {
  const classes = useStyles();
  const location = useLocation<{ from: Location }>();
  const sessionId = location.pathname.split('/')[1];
  const [value, loading, error] = useDocument(firebase.firestore().doc(`${GAME_DEF_COLLECTION}/${sessionId}`), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const [invalidSessionId, setInvalidSessionId] = useState(false);

  if (!loading && !error && value) {
    if (!value?.data() && !invalidSessionId) {
      setInvalidSessionId(true);
    }
  }

  const changeStep = (count: number) => {
    const totalSteps = value?.data()?.gameItems.length;
    const nextStep = value?.data()?.activeStep.step + count;

    if (nextStep >= 0 && nextStep < totalSteps) {
      firebase
        .firestore()
        .collection(GAME_DEF_COLLECTION)
        .doc(sessionId)
        .set({
          ...value?.data(),
          activeStep: { data: value?.data()?.gameItems[nextStep], step: nextStep },
        })
        .then(function () {
          console.log('Document successfully written!');
        });
    }
  };

  function renderStep() {
    const data = value?.data()?.activeStep?.data;

    if (!data) {
      return;
    }

    switch (data.type) {
      // @ts-ignore
      case 'QUIZ': {
        return (
          <Paper className={classes.paper} elevation={6}>
            <QuizView next={changeStep.bind(null, 1)} />
          </Paper>
        );
      }
      // @ts-ignore
      case 'VIDEO': {
        return (
          <div>
            <VideoView videoId={data.videoId} width='800' />
          </div>
        );
      }
      // @ts-ignore
      case 'PUZZLE': {
        return (
          <div>
            <PuzzleView />
          </div>
        );
      }
      default:
        return <div />;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container alignItems='flex-start' className={classes.container}>
        <div className={classes.bodyContainer}>
          {!loading && !error && value && renderStep()}
          {invalidSessionId && (
            <Paper className={classes.paper} elevation={6}>
              <div>Invalid Session ID</div>
            </Paper>
          )}
          <div>
            <Button variant='contained' className={classes.button} onClick={changeStep.bind(null, -1)}>
              {`<`}
            </Button>
            <Button variant='contained' className={classes.button} onClick={changeStep.bind(null, 1)}>
              {`>`}
            </Button>
          </div>
        </div>
      </Grid>
    </ThemeProvider>
  );
}
