import React, { useEffect } from 'react';
import cloudinary from 'cloudinary-core';
// Leave it, cloudinary-core doensn't work without this lib
// @ts-ignore
import cloudinaryVideoPlayer from 'cloudinary-video-player'; // eslint-disable-line

import 'cloudinary-video-player/dist/cld-video-player.css';

// @ts-ignore
export default function CloudinaryPlayer({ width = '680', publicId }) {
  useEffect(() => {
    // @ts-ignore
    const cloudinaryCore = new cloudinary.Cloudinary({
      cloud_name: 'living-security',
      secure: true,
      private_cdn: true,
    });

    const player = cloudinaryCore
      .videoPlayer('cld-video-player', {
        fluid: true,
        controls: true,
        floatingWhenNotVisible: true,
        hideContextMenu: false,
        showJumpControls: true,
        autoplay: true,
        playedEventPercents: [25, 50, 75, 100],
        colors: { 'base': '#08152E', 'accent': 'rgba(74, 255, 255, 0.45)', 'text': '#fff' },
        logoImageUrl: 'none',
      })
      .width(width);

    player.source(publicId, {
      sourceTypes: ['hls'],
      transformation: [
        {
          streamingProfile: 'hd',
        },
        {
          width: width,
          crop: 'scale',
        },
      ],
    });

    player.volume(0.5);

  }, [publicId, width]);

  return <video id='cld-video-player' controls width={width} />;
}
