const config = {
  apiKey: 'AIzaSyBjCAu7xKf-vGDWWAZvjdA8JdctVQLVt3I',
  authDomain: 'living-security-engineering.firebaseapp.com',
  databaseURL: 'https://living-security-engineering.firebaseio.com',
  projectId: 'living-security-engineering',
  storageBucket: 'living-security-engineering.appspot.com',
  messagingSenderId: '419324971525',
  appId: '1:419324971525:web:65d86aacc75c473d8d6a6c',
  measurementId: 'G-MV6X71M3ZR',
};

export default config;
