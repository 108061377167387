import React from 'react';
import { styled } from '@material-ui/core/styles';
import './db';

import MenuBar from './components/MenuBar/MenuBar';
import Room from './components/Room/Room';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import SessionSignupPage from './containers/SessionSignupPage/SessionSignupPage';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  overflow: 'hidden',
});

const Main = styled('main')({
  overflow: 'hidden',
  height: 'calc(100vh - 64px)',
});

export default function App() {
  const roomState = useRoomState();

  const height = useHeight(0);

  return (
    <Container style={{ height }}>
      <Main>{roomState === 'connected' ? <Room /> : <SessionSignupPage />}</Main>
      {roomState === 'connected' && <MenuBar />}
    </Container>
  );
}
