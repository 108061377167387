import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import config from './firebase-config';

firebase.initializeApp(config);

export const db = firebase.firestore();

db.enablePersistence().catch(function (err) {
  console.error(err);
});

export const GAME_DEF_COLLECTION = 'game-definitions';
