import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { createMuiTheme, createStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';
import { useAppState } from '../../state';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import LocalVideoPreview from '../../components/LocalVideoPreview/LocalVideoPreview';
import Controls from '../../components/Controls/Controls';
import SessionBottomInfoBar from '../../components/SessionBottomInfoBar/SessionBottomInfoBar';
import SignupBottomContent from '../../components/SessionBottomInfoBar/SignupBottomContent';
import ModalContainer from '../../components/Modal/ModalContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: '#08152E',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      overflowX: 'hidden',
    },
    settingsContainer: {
      position: 'relative',
      height: '280px',
      width: '550px',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '10px',
      margin: '30px 0',
      alignItems: 'center',
      background: 'rgba(255, 255, 255, .05)',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 280,
    },
    loadingSpinner: {
      marginLeft: '1em',
      color: '#6ebddb',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    button: {
      margin: '1em',
      background: '#6ebddb',
      color: '#FFF',
      '&:hover': {
        background: '#5B9CB5',
      },
    },
  })
);

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

export default function SessionSignupPage(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const sessionId = location.pathname.split('/')[1];
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect, isAcquiringLocalTracks } = useVideoContext();
  const roomState = useRoomState();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [suggestedTeamName, setTeamName] = useState<string>('');

  useEffect(() => {
    if (sessionId) {
      setRoomName(sessionId);
    }
  }, [sessionId]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleTeamNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTeamName(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    getToken(name, sessionId, suggestedTeamName).then(token => {
      connect(token).then(() => {
        history.push({ pathname: `/${sessionId}/play`, search: location.search });
      });
    });
  };

  navigator.permissions
    .query({ name: 'microphone' })
    .then(permissionObj => {
      // console.log(permissionObj.state);
    })
    .catch(error => {
      console.log('Got error :', error);
    });

  navigator.permissions
    .query({ name: 'camera' })
    .then(permissionObj => {
      // console.log(permissionObj.state);
    })
    .catch(error => {
      console.log('Got error :', error);
    });

  return (
    <ThemeProvider theme={theme}>
      <Grid container alignItems='flex-start' className={classes.container}>
        <ModalContainer title='Your Information'>
          {roomState === 'disconnected' && (
            <form className={classes.form} onSubmit={handleSubmit}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <div style={{ margin: '1em 0' }}>
                  {window.location.search.includes('customIdentity=true') || !user?.displayName ? (
                    <TextField
                      id='menu-name'
                      label='Your Player Name'
                      className={classes.textField}
                      value={name}
                      onChange={handleNameChange}
                      margin='dense'
                    />
                  ) : (
                    <Typography className={classes.displayName} variant='body1'>
                      {user.displayName}
                    </Typography>
                  )}
                </div>
                <div style={{ margin: '1em 0' }}>
                  {window.location.search.includes('customIdentity=true') || !user?.displayName ? (
                    <TextField
                      id='menu-name'
                      label='Suggested Team Name'
                      className={classes.textField}
                      value={suggestedTeamName}
                      onChange={handleTeamNameChange}
                      margin='dense'
                    />
                  ) : (
                    <Typography className={classes.displayName} variant='body1'>
                      {suggestedTeamName}
                    </Typography>
                  )}
                </div>
              </div>
              <div className={classes.settingsContainer}>
                <LocalVideoPreview />
                <Controls />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Button
                  className={classes.button}
                  type='submit'
                  variant='contained'
                  disabled={isAcquiringLocalTracks || isConnecting || !name || !roomName || isFetching}
                >
                  Sign In
                </Button>
                {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
              </div>
            </form>
          )}
        </ModalContainer>
      </Grid>
      <SessionBottomInfoBar>
        <SignupBottomContent
          sessionDetails={{
            id: 8432,
            start_time: moment().add(1, 'h').startOf('h'),
            game_title: 'Born Secure Entrance Exam',
          }}
        />
      </SessionBottomInfoBar>
    </ThemeProvider>
  );
}
