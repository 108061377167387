import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import React from 'react';
import useMainSpeaker from '../../hooks/useMainSpeaker/useMainSpeaker';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';

export default function MainParticipant() {
  const mainParticipant = useMainSpeaker();
  const screenShareParticipant = useScreenShareParticipant();

  const videoPriority = mainParticipant === screenShareParticipant ? 'high' : 'low';

  return (
    /* audio is disabled for this participant component because this participant's audio
       is already being rendered in the <ParticipantStrip /> component.  */
    <MainParticipantInfo participant={mainParticipant}>
      <ParticipantTracks
        participant={mainParticipant}
        disableAudio
        enableScreenShare
        mainPanel
        videoPriority={videoPriority}
      />
    </MainParticipantInfo>
  );
}
