import React from 'react';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import { styled } from '@material-ui/core/styles';
import MainParticipant from '../MainParticipant/MainParticipant';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: `${theme.sidebarWidth}px calc(100% - ${theme.sidebarWidth * 2 + 20}px) ${theme.sidebarWidth}px`,
  gridTemplateAreas: '". participantList"',
  gridTemplateRows: '100%',
  gridGap: '8px',
  [theme.breakpoints.down('xs')]: {
    gridTemplateAreas: '"participantList" "." "participantList"',
    gridTemplateColumns: `auto`,
    gridTemplateRows: `calc(100% - ${theme.sidebarMobileHeight + 12}px) ${theme.sidebarMobileHeight + 6}px`,
    gridGap: '6px',
  },
}));

export default function Room() {
  return (
    <Container>
      <ParticipantStrip side='left' />
      <MainParticipant />
      <ParticipantStrip side='right' />
    </Container>
  );
}
