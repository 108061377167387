import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import generateConnectionOptions from './utils/generateConnectionOptions/generateConnectionOptions';

import WaitingRoomPage from './containers/WaitingRoomPage/WaitingRoomPage';

import GameplayPage from './containers/GameplayPage/GameplayPage';
import QuizView from './containers/GameplayPage/views/QuizView/QuizView';
import VideoView from './containers/GameplayPage/views/VideoView/VideoView';

import LandingPage from './containers/LandingPage/LandingPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

const CyberEscapeOnlineApp = (props: any) => {
  const { error, setError, settings } = useAppState();
  const connectionOptions = generateConnectionOptions(settings);

  return (
    <UnsupportedBrowserWarning>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <props.component />
      </VideoProvider>
    </UnsupportedBrowserWarning>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AppStateProvider>
        <Switch>
          <PrivateRoute exact path='/'>
            <LandingPage />
          </PrivateRoute>
          <PrivateRoute exact path='/:sessionId/join'>
            <CyberEscapeOnlineApp component={App} />
          </PrivateRoute>
          <PrivateRoute exact path='/:sessionId/play'>
            <CyberEscapeOnlineApp component={App} />
          </PrivateRoute>
          <PrivateRoute exact path='/:sessionId'>
            <WaitingRoomPage />
          </PrivateRoute>

          {/* These routes are really just for local testing */}
          <PrivateRoute path='/:sessionId/gameplay'>
            <CyberEscapeOnlineApp component={GameplayPage} />
          </PrivateRoute>
          <PrivateRoute path='/:sessionId/quiz'>
            <CyberEscapeOnlineApp component={QuizView} />
          </PrivateRoute>
          <PrivateRoute path='/:sessionId/video'>
            <CyberEscapeOnlineApp component={VideoView} />
          </PrivateRoute>

          <Route path='*' component={LandingPage} />
          <Redirect to='/' />
        </Switch>
      </AppStateProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);
