import React from 'react';
import Participant from '../Participant/Participant';
import { styled } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const Container = styled('div')(({ theme }) => ({
  padding: '0.5em',
  overflowY: 'auto',
  [theme.breakpoints.down('xs')]: {
    overflowY: 'initial',
    overflowX: 'auto',
    padding: 0,
    display: 'flex',
  },
}));

const ScrollContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: 'flex',
  },
}));

export default function ParticipantStrip(props: { side: string }) {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();

  return (
    <Container>
      <ScrollContainer>
        {props.side === 'left' && <Participant participant={localParticipant} />}
        {participants.map((participant, index) => {
          if (index % 2 === 0 && props.side === 'right') {
            return <Participant key={participant.sid} participant={participant} />;
          } else if (index % 2 === 1 && props.side === 'left') {
            return <Participant key={participant.sid} participant={participant} />;
          } else {
            return null;
          }
        })}
      </ScrollContainer>
    </Container>
  );
}
