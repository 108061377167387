import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles({
  header: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  answerSection: {
    margin: '1em',
    width: '100%',
  },
  quizAnswers: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '700px',
  },
  button: {
    margin: '1em',
    background: '#6ebddb',
    color: '#FFF',
    width: '30%',
    minWidth: '330px',
    '&:hover': {
      background: '#5B9CB5',
    },
  },
});

export default function QuizView(props: any) {
  const classes = useStyles();
  const { localTracks } = useVideoContext();

  localTracks.forEach(track => {
    if (track.kind === 'audio') {
      track.enable();
    }
  });

  return (
    <div>
      <div className={classes.header}>
        <div>Malicious insiders are typically...</div>
      </div>
      <div className={classes.answerSection}>
        <div className={classes.quizAnswers}>
          {[
            '...employees who abuse security policy out of convenience.',
            '...employees who avoid being spied on by dancing past the CCTV cameras.',
            '...employees who unintentionally violate policy because of a lack of awareness or poor security hygiene.',
            'None of the above',
          ].map((answer, index) => {
            return (
              <Button key={index} variant='contained' className={classes.button} onClick={props.next}>
                {answer}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
