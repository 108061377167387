import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    fontSize: '20px',
    color: '#f2f2f2',
  },
  button: {
    margin: '1em 1em 1em 3em',
    fontSize: '14px',
    color: '#5dcdfc',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      color: '#5ed1ff',
    },
  },
});

export default function ({
  sessionDetails: { id, start_time, end_time, reschedule_link },
}: {
  sessionDetails: { id: string; start_time: any; end_time: any; reschedule_link: string };
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const debug = location.search.indexOf('debug') > -1;
  const tz = moment?.tz?.zone(moment?.tz?.guess())?.abbr(parseInt(moment().format('x')));
  const doorsOpen = moment(start_time).subtract(15, 'm');
  const timeTillStart = start_time.fromNow();
  const timeTillOpen = doorsOpen.fromNow();
  const canJoin = start_time.diff(moment(), 'm', true) < 15;
  const joinTitle = canJoin ? 'Session Open' : 'Session Not Open Yet';
  const joinSubtitle = canJoin
    ? `Game starts at ${start_time.format('h:mm A zz')} ${tz} (${timeTillStart})`
    : `Doors open at ${doorsOpen.format('h:mm A zz')} ${tz} (${timeTillOpen})`;

  const handleJoin = () => {
    history.push({ pathname: `/${id}/play`, search: location.search });
  };

  return (
    <>
      <div>
        <div>
          <div className={classes.title}>{joinTitle}</div>
          <div>{joinSubtitle}</div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ maxWidth: '365px', textAlign: 'right' }}>
          <div>
            {!canJoin &&
              'The doors to this session have not opened. If you can’t make this session, please reschedule.'}
          </div>
        </div>
        <div>
          {canJoin || debug ? (
            <a className={classes.button} onClick={handleJoin}>
              JOIN
            </a>
          ) : (
            <a className={classes.button} href={reschedule_link}>
              RESCHEDULE
            </a>
          )}
        </div>
      </div>
    </>
  );
}
