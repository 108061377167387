import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: '80px',
    marginTop: '2.5em',
    padding: '1em 2em',
    background: 'rgba(0, 115, 160, 0.75)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'default',
  },
});

export default function (props: any) {
  const classes = useStyles();
  return <div className={classes.container}>{props.children}</div>;
}
