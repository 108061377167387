import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    fontSize: '18px',
    color: '#f2f2f2',
  },
  gameTitle: {
    fontSize: '24px',
    color: '#f2f2f2',
  },
});

export default function ({
  sessionDetails: { id, start_time, game_title },
}: {
  sessionDetails: { id: number; start_time: any; game_title: string };
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const debug = location.search.indexOf('debug') > -1;
  const tz = moment?.tz?.zone(moment?.tz?.guess())?.abbr(parseInt(moment().format('x')));
  const canJoin = start_time.diff(moment(), 'm', true) < 15;

  const handleJoin = () => {
    history.push({ pathname: `/${id}/join`, search: location.search });
  };

  return (
    <>
      <div>
        <div>
          <div className={classes.title}>CyberEscape Online</div>
          <div className={classes.gameTitle}>{game_title}</div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
        <div>{`${start_time.format('MMMM Do, YYYY h:mm A zz')} ${tz}`}</div>
        <div>{`Session #${id}`}</div>
      </div>
    </>
  );
}
