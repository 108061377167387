import React from 'react';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../VideoTrack/VideoTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export default function LocalVideoPreview() {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(track => track.name.includes('camera')) as LocalVideoTrack;

  return videoTrack ? (
    <VideoTrack track={videoTrack} isLocal />
  ) : (
    <div style={{ height: '200px', fontSize: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      Test Video/Audio
    </div>
  );
}
